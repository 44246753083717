import React, { ReactElement } from "react";
import MainBanner from "@components/clearyourdebtsfast/mainBanner/mainBanner";
import { GetServerSideProps } from "next";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import OffersList from "@components/shared/offersList";
import DebtReliefSavior from "@components/clearyourdebtsfast/debtReliefSavior";
import StreamlinedTheProcess from "@components/clearyourdebtsfast/streamlinedTheProcess";
import FinancialFreedom from "@components/clearyourdebtsfast/financialFreedom";
import TakeTheReins from "@components/clearyourdebtsfast/takeTheReins";
import ClearYourDebtsFastWrapper from "@components/layouts/clearYourDebtsFastWrapper";
import styles from "./styles.module.scss";
export default function ClearYourDebtsFast(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <ClearYourDebtsFastWrapper>
            <div className={styles["wrapper"]}>
                <MainBanner />
            </div>
            <section style={{ padding: "0 15px" }}>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <DebtReliefSavior />
            <StreamlinedTheProcess />
            <FinancialFreedom />
            <TakeTheReins />
        </ClearYourDebtsFastWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
